import { useEffect, useState } from "react";
import general from "../../general";
import dayjs from "dayjs";
import { NavLink } from "../../lib/Navigation";
import Dialog from "../../lib/Dialog";
import { sysUrl } from "../../config";

export default (props) => {
  const [calendar, setCalendar] = useState([]);
  useEffect(() => {
    let days = [];
    let startDay = dayjs(dayjs().format("YYYY-MM-01")).day();
    if (startDay !== 0) {
      for (let i = 0; i < startDay; i++) {
        days.push(dayjs(dayjs().format("YYYY-MM-01")).add(-(i + 1), "days"));
      }
      days.reverse();
    }
    let i = 0;
    while (true) {
      let day = dayjs(dayjs().format("YYYY-MM-01")).add(i, "days");
      if (
        day.format("YYYY-MM-DD") ===
        dayjs().add(1, "months").format("YYYY-MM-01")
      ) {
        break;
      }
      days.push(day);
      i++;
    }
    while (true) {
      if (days.length % 7 !== 0) {
        let tmp = days[days.length - 1];
        days.push(dayjs(tmp).add(1, "days"));
      } else {
        break;
      }
    }
    setCalendar(days);
  }, []);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});
  const [signInfo, setSignInfo] = useState({});
  const [sign, setSign] = useState(false);
  const load = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    general.axios
      .post("/user/task/getData")
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        setLoaded(true);
        let result = response.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {
          setData(result.data);
          setSignInfo(result.data.sign_info);
          if (!result.data.sign_info.today_issign) {
            Dialog.loading();
            general.axios
              .post("/user/task/sign")
              .finally(() => {
                Dialog.close();
              })
              .then((res) => {
                let result = res.data;
                if (!result.status.succeed) {
                  Dialog.error(result.status.error_desc);
                } else {
                  setSign(true);
                  setData({ ...data, ...result.data });
                  setSignInfo({
                    ...signInfo,
                    sign_date_list: result.data.sign_date_list,
                  });
                  try {
                    general.task((data) => ({ ...data, ...result.data }));
                    general.sign((data) => ({ ...data, today_issign: 1 }));
                  } catch (e) {}
                }
              })
              .catch((err) => Dialog.error("sign " + err.message));
          }
        }
      })
      .catch((error) => Dialog.error(error.message));
  };
  useEffect(() => {
    if (props.active && (!loaded || props.action === "POP")) {
      load();
    }
  }, [props]);

  return (
    <div
      className="sign-index"
      style={{ backgroundImage: "url(/resources/task/sign-bg.png)" }}
    >
      <div className="sign-title">
        <img src="/resources/task/sign-title-9917.png" alt="" />
        本月已经累计签到{data.month_sign_day}天
      </div>
      <div className="sign-calendar">
        <div className="title">{dayjs().format("YYYY年MM月")}</div>
        <div className="main clearfix">
          <div className="item disable">日</div>
          <div className="item disable">一</div>
          <div className="item disable">二</div>
          <div className="item disable">三</div>
          <div className="item disable">四</div>
          <div className="item disable">五</div>
          <div className="item disable">六</div>
          {calendar.map((item, index) => (
            <div
              key={index}
              className={
                "item" +
                (item.format("YYYY-MM") !== dayjs().format("YYYY-MM")
                  ? " disable"
                  : " sign") +
                (signInfo.sign_date_list?.find(
                  (item2) => item2 === item.format("YYYY-MM-DD")
                )
                  ? " active"
                  : "")
              }
              style={{
                backgroundImage:
                  "url(" +
                  (signInfo.sign_date_list?.find(
                    (item2) => item2 === item.format("YYYY-MM-DD")
                  )
                    ? "/resources/task/sign2.png"
                    : item.format("YYYY-MM") === dayjs().format("YYYY-MM")
                    ? "/resources/task/sign1.png"
                    : "") +
                  ")",
              }}
            >
              {item.format("DD")}
            </div>
          ))}
        </div>
      </div>
      <div className="sign-operate">
        <NavLink
          to={`${sysUrl}/home/SuperMember`}
          iframe={{ title: "开通会员 享积分加成" }}
          className="vip"
        >
          开通会员 享积分加成
        </NavLink>
        <div
          className="role"
          onClick={() =>
            Dialog.alert(
              "签到规则",
              <>
                <p>1.每日签到完成，即可获得3个成长值</p>
                <p>2.每满3天/7天/14天/21天/28天多送一次每日抽奖</p>
                <p>3.以每个自然月为维度进行天数累计，不需要连续签到</p>
              </>
            )
          }
        >
          查看签到规则
        </div>
      </div>
      {loaded && !signInfo.today_issign && sign ? (
        <div className="sign-alert">
          <div className="mask" onClick={() => setSign(false)} />
          <div
            className="entity"
            style={{ backgroundImage: "url(/resources/task/sign-alert.png)" }}
          >
            <div className="info">
              恭喜您本次签到
              <br />
              获得{data.today_sign_get_integral}成长值
            </div>
            <div className="desc">
              您本月已经累计签到{data.month_sign_day}天
            </div>
            <div className="btn" onClick={() => setSign(false)}>
              好的
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
