// 分类-精选
import React, { useEffect, useState } from "react";
import general from "../../../general";
import { Link } from "react-router-dom";
import SwiperCore, { Pagination } from "swiper";
import { useScroll } from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import Banner from "./Banner";

SwiperCore.use([Pagination]);

export default (props) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [banners, setBanners] = useState([]);
  const [gameGroups, setGameGroups] = useState([]);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();

    general.axios
      .post("/base/appcategorize/getAppCategorizeData", {
        categorize_id: props.tag,
      })
      .finally(() => {
        setLoading(false);
        Dialog.close();
        parms.finishPullDown && parms.finishPullDown();
      })
      .then((response) => {
        console.log("分类对应数据列表", response);
        setInit(true);
        let result = response.data.data.one;
        setBanners(result?.banner);
        setGameGroups(result?.special);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
    }
  }, [scroll, props.active]);

  return (
    // banner+专题
    <div className="game-good classify-banner">
      {banners.length > 0 || gameGroups.length > 0 ? (
        <>
          <div className="banners">
            <Banner banners={banners} type={1} />
          </div>
          <div className="groups">
            {gameGroups.map((group) => (
              <div key={group.id} className="group">
                <div className="head">
                  <div className="name">{group.title}</div>
                  <Link to={`/game/indexProject?id=${group.id}&type=classify`}>
                    <div className="more">更多 &gt;</div>
                  </Link>
                </div>
                <div className="body clearfix">
                  {group.list?.map((game) => (
                    <Link
                      key={game.game_id}
                      to={"/game?id=" + game.game_id}
                      className="item"
                    >
                      <div className="icon">
                        <Image src={game.icon} />
                        {game.discount && game.discount < 1 ? (
                          <div
                            className="index-all-discount"
                            style={{ borderRadius: "0px 8px 0px 10px" }}
                          >
                            <span
                              style={{
                                fontSize: "0.24rem",
                                fontWeight: "bold",
                              }}
                            >
                              {parseFloat((game.discount * 10).toFixed(2))}
                            </span>
                            <span style={{ fontSize: "0.2rem" }}>折</span>
                          </div>
                        ) : null}
                      </div>

                      <div className="name">
                        {game.game_name_main || game.game_name}
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {gameGroups.length > 0 ? (
            <div className="view-end">别撩啦！已经到底啦~</div>
          ) : null}
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            paddingTop: "30px",
          }}
        >
          <img style={{ width: "70%" }} src="/resources/empty.png" alt="" />
          <p>暂无数据~</p>
        </div>
      )}
    </div>
  );
};
