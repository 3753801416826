import { useState } from "react";
import { CSSTransition } from "react-transition-group";

let ios16;
let res = navigator.userAgent.match(/OS (\d+).* like Mac OS/);
let version;
if (res) {
  version = res[1];
  ios16 = res[1] >= 16;
}
console.warn({ ios16 });

export default (props) => {
  const [confirm, setConfirm] = useState(!ios16);
  const superShow = true;

  // 在 JavaScript (JS) 中，无法直接通过代码来判断用户是否已经开启了iOS设备的开发者模式。因为开发者模式属于操作系统的功能，需要由用户手动进行设置并且只有特定版本的iOS才支持该功能（如iOS 9及以上）
  // 原生应用程序可以获取

  return (
    <>
      {confirm ? (
        props.children
      ) : (
        <>
          <CSSTransition in={superShow} timeout={250} unmountOnExit={true}>
            <div
              className="game-ios-guide"
              style={{ justifyContent: "flex-end" }}
            >
              <div className="super-game-container">
                <div
                  className="super-game-title"
                  style={{
                    backgroundImage:
                      "url(/resources/download/super-title-back.png)",
                  }}
                >
                  <div className="title-font">下载提示</div>
                </div>

                <div style={{ padding: "0 16px" }}>
                  <div className="super-game-tips">
                    因您的系统升级为iOS{version}
                    ，现按照iOS官方要求，需开启开发者模式才可进入游戏
                  </div>

                  <div className="super-game-tips">
                    <p className="title">开启流程如下</p>
                    <div className="tips-step-one">
                      <div className="step-every">
                        <div className="step-eveery-font">
                          <p className="serial">1</p>
                          <span>
                            打开设置，点击
                            <span style={{ color: "#333333" }}>
                              【隐私与安全性】
                            </span>
                          </span>
                        </div>

                        <img src="/resources/download/super01.png" alt="" />
                      </div>

                      <div className="step-every">
                        <div className="step-eveery-font">
                          <p className="serial">2</p>
                          <span>
                            点击
                            <span style={{ color: "#333333" }}>
                              【开发者模式】
                            </span>
                            ，进入后
                            <span style={{ color: "#333333" }}>【开启】</span>
                            开发者模式，弹窗选择
                            <span style={{ color: "#333333" }}>
                              【重新启动】
                            </span>
                          </span>
                        </div>

                        <img src="/resources/download/super02.png" alt="" />
                      </div>
                    </div>

                    <div className="tips-step-one tips-step-two">
                      <div style={{ width: "48%" }}>
                        <div className="step-every" style={{ width: "100%" }}>
                          <div className="step-eveery-font">
                            <p className="serial">3</p>
                            <span>
                              重启手机后桌面会弹出确认是否开启开发者模式的弹窗，点击
                              <span style={{ color: "#333333" }}>【打开】</span>
                            </span>
                          </div>

                          <img src="/resources/download/super03.png" alt="" />
                        </div>

                        <div className="step-every" style={{ width: "100%" }}>
                          <div className="step-eveery-font">
                            <p className="serial">4</p>
                            <span>输入锁屏密码后即可开启了开发者模式</span>
                          </div>

                          <img src="/resources/download/super04.png" alt="" />
                        </div>
                      </div>

                      <div className="step-every">
                        <img src="/resources/download/super05.png" alt="" />
                        <img src="/resources/download/super06.png" alt="" />

                        <div className="step-eveery-font">
                          <p className="serial">5</p>
                          <span>进入游戏详情页下载游戏</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="super-game-btn"
                    onClick={() => setConfirm(true)}
                  >
                    我知道了
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        </>
      )}
    </>
  );
};
