import Image from "../../lib/Image";
import { NavLink } from "../../lib/Navigation";
import { useEffect, useState } from "react";
import { useRequest, useUpdate } from "ahooks";
import general from "../../general";
import useActiveState from "../../lib/useActiveState";
import dayjs from "dayjs";
import MobileDetect from "mobile-detect";

const mobileDetect = new MobileDetect(navigator.userAgent);

export default (props) => {
  const update = useUpdate();
  const [app] = useActiveState("app");
  const [user] = useActiveState("user");
  const [datas, setDatas] = useState();
  const [data, setData] = useState();
  const [alerted, setAlerted] = useState();

  // useRequest(async () => {
  //   return general.axios
  //     .post("/movable/popup/popup")
  //     .then(({ data: resData }) => {
  //       console.log("右下角悬浮球--------", resData);
  //       let { status, data } = resData;
  //       if (status.succeed) {
  //         setDatas({
  //           ...data,
  //           route: data?.jump_value,
  //         });
  //       }
  //     });
  // });

  useEffect(() => {
    if (props.active) {
      if (sessionStorage.getItem("popupData")) {
        console.log(
          "popupData-----------------",
          sessionStorage.getItem("popupData")
        );

        setDatas(JSON.parse(sessionStorage.getItem("popupData")));
        setData(JSON.parse(sessionStorage.getItem("popupData")));
        sessionStorage.setItem("popupData", "");
      } else {
        if (!alerted) {
          general.axios
            .post("/movable/popup/popup")
            .then(({ data: resData }) => {
              console.log("右下角悬浮球--------", resData);
              let { status, data } = resData;
              if (status.succeed) {
                setDatas({
                  ...data,
                  route: data?.jump_value,
                });
              }
            });
        }
      }
    }
  }, [props.active]);

  const [tikShow] = useActiveState("tikShow");
  useEffect(() => {
    if (
      window.navigator.standalone
      //  ||
      // !(
      //   mobileDetect.is("iPhone") ||
      //   mobileDetect.is("iPad") ||
      //   navigator.userAgent === "app"
      // ) ||
      // window.location.hostname === "localhost" ||
      // window.location.hostname.startsWith("192.168")
    ) {
      if (!alerted) {
        if (datas) {
          // if (tikShow?.activity === 1) {
          setData(datas);
          // }
        }
      }
    }
    update();
  }, [datas]);

  const tips = (data) => {
    setData(data);
    console.log("tips----", data);
    spm(data, "tips");
  };
  const alertFunc = (data) => {
    setData(null);
    window.localStorage.setItem(`act_${data.id}`, dayjs().valueOf());
    spm(data, "alert");
    setAlerted(true);
  };
  const close = (data) => {
    setData(null);
    window.localStorage.setItem(`act_${data.id}`, dayjs().valueOf());
    spm(data, "close");
    setAlerted(true);
  };

  const spm = (data, opt) => {
    general.axios.post("/spm", {
      spm: `activity${data.id ? `.${data.id}` : ""}.${opt}`,
    });
  };

  return (
    <>
      {/* 右下角悬浮球 */}
      {datas && datas.thumb_images && (
        <div
          className={
            "act-datas " +
            (props.isShow === "open" && props.advertis?.img_url
              ? "act-datas-113"
              : "act-datas-60")
          }
        >
          <div className="item" onClick={() => tips(datas)}>
            <Image
              className={app.brand}
              src={datas?.thumb_images}
              alt={datas?.title}
            />
          </div>
        </div>
      )}

      {/* 弹窗 */}
      {data && data.image && (
        <div className="act-data">
          <div className="container">
            <NavLink
              to={
                data.jump_type == 2
                  ? `${data.route}`
                  : `/game?mid=${data.route}`
              }
              iframe={
                data?.route?.startsWith("http")
                  ? { title: data.title }
                  : undefined
              }
              user={data.verify_login == 1 ? true : false}
              onClick={() => alertFunc(data)}
            >
              <div className="img">
                <Image src={data.image} alt="" />
              </div>
            </NavLink>
            <div className="close" onClick={() => close(data)}>
              X
            </div>
          </div>
        </div>
      )}
    </>
  );
};
