import { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useLocalStorageState } from "ahooks";
import general from "../../general";
import Cover from "./Cover";
import Result from "./Result";
import useActiveState from "../../lib/useActiveState";
import useUrlState from "@ahooksjs/use-url-state";

import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Dialog from "../../lib/Dialog";

SwiperCore.use([Autoplay]);

export default ({ active, setTitle, setOperate }) => {
  const [config] = useActiveState("config");
  const [query, setQuery] = useUrlState(
    { kw: "" },
    { navigateMode: "replace" }
  );
  const [refresh, setRefresh] = useState("");

  let [isShow, setIsShow] = useState(true);
  let [kws, setKws] = useLocalStorageState("search-kws", {
    defaultValue: [],
  });
  let [swiperIndex, setSwiperIndex] = useState(null);

  useEffect(() => {
    general.axios
      .get("/base/search/index", {
        headers: { username: "" },
      })
      .then((res) => {
        let { status, data } = res.data;
        console.log("搜索页数据", data);
        if (!status.succeed) {
          return;
        }
        kws = data.search_keyword;
        setKws(data.search_keyword);
        console.log("热搜词------------", data.search_keyword);
      })
      .catch((err) => {
        console.log("search " + err.message);
      });
  }, []);

  let clickCount = 0;

  useEffect(() => {
    setTitle(
      <div className="search-index-input">
        <img
          className="search-index-input-icon"
          src="/resources/comment/search.png"
          alt=""
        />

        <div className="search-index-input-main">
          {kws.length > 0 && isShow ? (
            <div className="search-index-input-main-swiper">
              <Swiper
                autoplay={true}
                loop={true}
                direction="vertical"
                onTransitionEnd={(data) => {
                  swiperIndex = data.activeIndex;
                  setSwiperIndex(data.activeIndex);
                }}
              >
                {kws.map((item, index) => (
                  <SwiperSlide
                    key={index}
                    className="search-index-input-main-swiper-slide"
                  >
                    <div>{item}</div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          ) : null}

          <input
            className="search-index-input-main-input"
            type="text"
            value={query.kw}
            placeholder={kws.length > 0 ? "" : "请输入关键字搜索"}
            onMouseDown={() => {
              clickCount++;
              if (clickCount > 1) {
                Dialog.info(
                  "若遇到iOS系统键盘问题，请重启手机或我的->联系客服"
                );
                clickCount = 0;
              }
            }}
            onChange={(e) => {
              swiperIndex = null;
              setSwiperIndex(null);
              setQuery({ kw: e.target.value });
              if (e.target.value != "") {
                isShow = false;
                setIsShow(false);
              } else {
                isShow = true;
                setIsShow(true);
              }
            }}
          />
        </div>

        {query.kw ? (
          <CloseOutlined
            className="close"
            onClick={() => {
              console.log(111111111111111);
              setQuery({ kw: "" });
              isShow = true;
              setIsShow(true);
            }}
          />
        ) : null}
      </div>
    );

    setOperate(
      <div
        className="search-index-btn"
        onClick={() => {
          if (swiperIndex) {
            let font = "";
            if (swiperIndex > kws.length) {
              font = kws[swiperIndex - 2];
            } else {
              font = kws[swiperIndex - 1];
            }
            console.log("font--------", font);
            isShow = false;
            setIsShow(false);
            query.kw = font;
            setQuery({ kw: font });
            console.log(isShow);
          }

          setQuery({ kw: query.kw || config.app_home_search_default_title });
          setRefresh({});
        }}
      >
        搜索
      </div>
    );
  }, [query.kw, kws]);

  const [gameList, setGameList] = useState([]);
  useEffect(() => {
    Dialog.loading();
    general.axios
      .post("/base/game/getGameList", {
        type: 2,
        pagination: { page: 1 },
      })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        console.log("热玩榜前五", res);
        let { status, data } = res.data;
        if (!status.succeed) {
          return;
        }
        console.log(data);
        setGameList(data.game_list.splice(0, 5));
      })
      .catch((err) => {
        // Dialog.error('search ' + err.message);
      });
  }, []);

  return (
    <>
      {active ? (
        <div className="search-index">
          <Cover
            active={active && !query.kw}
            kw={query.kw}
            setKw={(kw) => setQuery({ kw })}
            setIsShow={(isShow) => setIsShow(isShow)}
            setSwiperIndex={(swiperIndex) => setSwiperIndex(swiperIndex)}
          />
          <Result
            active={active && !!query.kw}
            kw={query.kw}
            refresh={refresh}
            gameList={gameList}
          />
        </div>
      ) : null}
    </>
  );
};
