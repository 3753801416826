import { useEffect, useState } from "react";
import { prod } from "../../config";
import { CloseOutlined } from "@ant-design/icons";
import general from "../../general";
import { useInterval } from "ahooks";
import Dialog from "../../lib/Dialog";

// 绑定手机
export default (props) => {
  useEffect(() => {
    props.setOperate(
      <div onClick={() => general.goBack(true)} className="account-phone">
        跳过
      </div>
    );
  }, []);
  const [data, setData] = useState(
    !prod ? { mobile: "13227737527", code: "123456" } : {}
  );
  const [focus, setFocus] = useState("");

  const [coding, setCoding] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const code = () => {
    if (!data.mobile) {
      Dialog.error("请输入手机号");
      return;
    }
    if (coding || codeTime) {
      return;
    }
    setCoding(true);
    Dialog.loading();

    general.axios
      .post("/base/tool/getVerifyCode", {
        mobile: data.mobile,
        code: "code",
        type: "02",
      })
      .finally(() => {
        setCoding(false);
        Dialog.close();
      })
      .then((response) => {
        let result = response.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {
          setCodeTime(60);
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };

  const [saving, setSaving] = useState(false);
  const save = () => {
    if (saving) {
      return;
    }
    if (!data.mobile) {
      Dialog.error("请输入手机号");
      return;
    }
    if (!data.code) {
      Dialog.error("请输入验证码");
      return;
    }
    setSaving(true);
    Dialog.loading();
    general.axios
      .post("/user/user/bindPhone", data)
      .finally(() => {
        setSaving(false);
        Dialog.close();
      })
      .then((response) => {
        let { status } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success("保存成功", () => general.goBack(true));
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };

  return (
    <div className="account-phone">
      <div className="input-item">
        <div className="label">手机号</div>
        <div className="input">
          <input
            type="number"
            value={data.mobile}
            placeholder="请输入手机号"
            autoComplete="off"
            onFocus={(e) => setFocus("mobile")}
            onChange={(e) => setData({ ...data, mobile: e.target.value })}
          />
          {focus === "mobile" && data.mobile ? (
            <CloseOutlined
              className="assist close"
              onClick={() => setData({ ...data, mobile: "" })}
            />
          ) : null}
        </div>
      </div>
      <div className="input-item">
        <div className="label">验证码</div>
        <div className="input">
          <input
            type="tel"
            value={data.code}
            placeholder="请输入验证码"
            autoComplete="off"
            onFocus={(e) => setFocus("code")}
            onChange={(e) => setData({ ...data, code: e.target.value })}
          />
          {focus === "code" && data.code ? (
            <CloseOutlined
              className="assist close"
              onClick={() => setData({ ...data, code: "" })}
            />
          ) : null}
        </div>
        <div
          className={"btn " + (coding || codeTime ? "disable" : "")}
          onClick={code}
        >
          {coding ? "发送中" : codeTime ? codeTime + "秒后重发" : "发送验证码"}
        </div>
      </div>
      <div className={"operate " + (saving ? "disable" : "")} onClick={save}>
        保存
      </div>
    </div>
  );
};
