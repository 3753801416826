import { useEffect, useState, useRef } from "react";
import { useMount, useLockFn, useScroll, useSize } from "ahooks";
import { CSSTransition } from "react-transition-group";
import { CloseOutlined } from "@ant-design/icons";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import general from "../../general";
import $ from "jquery";

export default (props) => {
  useMount(() => {
    let voucher = props.voucher;
    console.log("代金券----", voucher);

    Dialog.alert(
      "使用方法",
      <div>
        <div style={{ display: "flex" }}>
          {["superMember", "monthcard"].includes(voucher.use_target) ? (
            `可用范围：仅限购买${voucher.game_name}使用`
          ) : voucher.origin_type == "platform" ? (
            <p>
              可用范围：平台绝大数游戏充值使用，特殊游戏不能使用
              <span
                style={{ color: "#0aa1ed", paddingLeft: "10px" }}
                onClick={() => {
                  setMore(true);
                  keyword = "";
                  setKeyword("");
                  scrollDir = 0;
                  setScrollDir(0);

                  setIsEmpty(true);
                  setNotGameList([]);
                  getGameList({ page: 1 });

                  document.body.style.overflow = "hidden";

                  setJurisdictionShow(true);
                }}
              >
                不可使用游戏列表&gt;
              </span>
            </p>
          ) : (
            `可用范围：仅限《${voucher.game_name_main || voucher.game_name}》`
          )}
        </div>
        有效期：{voucher.end_time_str || voucher.end_time}到期
        <br />
        使用条件：满{voucher.meet_amount * 1}元可用
        <br />
        使用方法：
        <br />
        {voucher.use_target == "superMember" ? (
          <div>
            APP—{">"}福利中心—{">"}超级会员—{">"}购买
            {{ month: "月", year: "年" }[voucher.game_id] || "超级会员"}
            卡，选择使用即可
          </div>
        ) : (
          <>
            {voucher.use_target == "monthcard" ? (
              <div>
                APP—{">"}福利中心—{">"}省钱卡—{">"}购买
                {{ month: "月", year: "年" }[voucher.game_id] || "省钱"}
                卡，选择使用即可
              </div>
            ) : (
              <div>
                1.使用App账号登录游戏；
                <br />
                2.在游戏内购买道具；
                <br />
                3.在支付页面选择可用的优惠券，完成支付。
                <br />
              </div>
            )}
          </>
        )}
        {voucher.use_target == "monthcard" &&
          "特别说明：新人限时折扣和优惠券不能一起使用。"}
      </div>,
      () => props.setVoucher(null),
      { btn: "我知道了" }
    );
  });

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [more, setMore] = useState(true);
  // 弹窗的高度 80vh
  const noGameRef = useRef(null);
  const sizeMain = useSize(noGameRef);
  const scroll = useScroll(noGameRef);

  // 弹窗 滚动的主体 高度是所有游戏列表渲染后的实、容器高度
  const scrollRef = useRef(null);
  const sizeNoHeight = useSize(scrollRef);
  let [scrollDir, setScrollDir] = useState(0);
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top + sizeMain?.height >= sizeNoHeight?.height && more) {
        if (scroll?.top > scrollDir) {
          getGameList({ page: page + 1 });
        }
      }
    }
  }, [scroll, props.active]);

  const notHeadRef = useRef(null);
  const sizeNotBody = useSize(notHeadRef);
  const [jurisdictionShow, setJurisdictionShow] = useState(false);
  let [keyword, setKeyword] = useState("");

  const [notGameList, setNotGameList] = useState([]);
  // 获取福利币不可用游戏列表
  // 搜索框change事件 开始搜索
  const getGameList = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      page,
      page_size: 40,
      search: keyword,
    };
    if (parms.page) {
      tmp.page = parms.page;
    }

    console.log("keyword---------", keyword);

    await general.axios
      .get("/movable/welfare/cannotUsePlatformCouponGameList", {
        params: tmp,
      })
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        console.log("福利币不可使用游戏", data);
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        setInit(true);

        scrollDir = scroll?.top || 0;
        setScrollDir(scroll?.top || 0);

        if (parms.page) {
          setPage(parms.page);
        }
        let { list } = data;
        setNotGameList((datas) =>
          parms.page === 1 || !parms.page ? list : [...datas, ...list]
        );

        setMore(list.length > 0);
      })
      .catch((err) => {
        Dialog.error("unusableFlb " + err.message);
      });
    // }, 500);
  });

  const checkData = (num) => {
    noGameRef.current.scrollTop = 0;
    scroll.top = 0;
    setMore(true);
    scrollDir = 0;
    setScrollDir(0);
    setNotGameList([]);

    if (num) {
      getGameList({ page: 1 });
    }
  };

  const [isEmpty, setIsEmpty] = useState(true);

  return (
    <>
      <CSSTransition in={jurisdictionShow} timeout={250} unmountOnExit={true}>
        <div className="flb-not-content flb-not-voucher">
          <div
            className="server-mask"
            onClick={() => {
              setJurisdictionShow(false);
              document.body.style.overflow = "";
            }}
          />
          <div className="server-main" ref={noGameRef}>
            <div
              style={{
                position: "relative",
                paddingBottom: "16px",
                overflow: "auto",
              }}
              ref={scrollRef}
            >
              <div className="gift-top" ref={notHeadRef}>
                <div className="down-title">
                  <span className="title">不可用游戏</span>
                  <img
                    className="close"
                    src="/resources/game/close.png"
                    alt=""
                    onClick={() => {
                      setJurisdictionShow(false);
                      document.body.style.overflow = "";
                    }}
                  />
                </div>
                <div className="not-game-input">
                  <div className="flb-search-input">
                    <img
                      src="/resources/comment/search.png"
                      alt=""
                      className="icon"
                    />
                    <input
                      type="text"
                      value={keyword}
                      placeholder={"请输入游戏名"}
                      onChange={(e) => {
                        keyword = e.target.value;
                        setKeyword(e.target.value);
                      }}
                      onInput={(e) => {
                        if (e.target.value == "" && !isEmpty) {
                          setIsEmpty(true);
                          keyword = "";
                          setKeyword("");
                          console.log("nonennnnnnnnnnnnnnnn");
                          checkData(1);
                        }
                      }}
                      onCompositionEnd={(e) => {
                        setIsEmpty(false);
                        console.log("end----dddddddddd-----", e.target.value);
                        keyword = e.target.value;
                        setKeyword(e.target.value);
                        console.log("kkkkkkkkkkkkkkkk----", keyword);
                        checkData(1);
                      }}
                    />
                    {keyword ? (
                      <CloseOutlined
                        className="clear"
                        onClick={() => {
                          keyword = "";
                          setKeyword("");
                          setIsEmpty(true);
                          checkData(1);
                        }}
                      />
                    ) : null}
                  </div>
                  <div
                    className="btn"
                    onClick={() => {
                      setIsEmpty(true);
                      checkData(1);
                    }}
                  >
                    搜索
                  </div>
                </div>
              </div>

              {notGameList && notGameList.length > 0 ? (
                <div
                  className="not-game-main"
                  style={{ paddingTop: sizeNotBody?.height || "111px" }}
                >
                  {notGameList.map((game) => (
                    <div className="item" key={game.gameId}>
                      <div className="icon">
                        <Image src={game?.gameIcon} alt="" />
                        {game.rechargeDiscount && game.rechargeDiscount < 1 && (
                          <div className="discount">
                            <span
                              style={{
                                fontSize: "0.24rem",
                                fontWeight: "bold",
                              }}
                            >
                              {parseFloat(
                                (game.rechargeDiscount * 10).toFixed(2)
                              )}
                            </span>
                            <span style={{ fontSize: "0.2rem" }}>折</span>
                          </div>
                        )}
                      </div>
                      <div className="name">{game.gameNameMain}</div>
                      {game.gameNameBranch && (
                        <div className="branch">
                          <p>{game.gameNameBranch}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  style={{ paddingTop: sizeNotBody?.height + 99 || "211px" }}
                  className="no-data"
                >
                  未搜索到该游戏~
                </div>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
