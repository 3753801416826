import React, { useEffect, useState, useRef } from "react";
import general from "../../general";
import $ from "jquery";
import { useLockFn, useScroll, useSize } from "ahooks";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import { CSSTransition } from "react-transition-group";
import { CloseOutlined } from "@ant-design/icons";
import Image from "../../lib/Image";

export default (props) => {
  const [user, setUser] = useActiveState("user");
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState();
  const [datas, setDatas] = useState([]);
  const [confirm, setConfirm] = useState();
  const load = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      pagination: { count: 50, page: 1 },
    };

    general.axios
      .post("/user/integralShop/platformFlbCurrencyList", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        console.log("福利币可兑换列表", data);
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        let { ptbList: list } = data;
        setDatas(() => list);
      })
      .catch((err) => {
        Dialog.error("platformFlbCurrencyList " + err.message);
      });
  };
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const [page, setPage] = useState(1);
  const [more, setMore] = useState(true);
  // 弹窗的高度 80vh
  const noGameRef = useRef(null);
  const sizeMain = useSize(noGameRef);
  const scroll = useScroll(noGameRef);

  // 弹窗 滚动的主体 高度是所有游戏列表渲染后的实、容器高度
  const scrollRef = useRef(null);
  const sizeNoHeight = useSize(scrollRef);
  let [scrollDir, setScrollDir] = useState(0);
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top + sizeMain?.height >= sizeNoHeight?.height && more) {
        console.log("1111111111111", scroll?.top, scrollDir);
        if (scroll?.top > scrollDir) {
          // alert(1111);
          getGameList({ page: page + 1 });
        }
      }
    }
  }, [scroll, props.active]);

  const exchange = useLockFn(async (data) => {
    if (!confirm) {
      Dialog.confirm({
        title:
          "您确认使用" +
          data.goods_price +
          "积分兑换" +
          data.flb_num +
          "福利币吗？",
        yes: async () => {
          Dialog.loading();
          await general.axios
            .post("/user/integralShop/exchangeFlb", { g_id: data.g_id })
            .finally(() => {
              Dialog.close();
            })
            .then((res) => {
              let { status } = res.data;
              if (!status.succeed) {
                Dialog.error(status.error_desc);
              } else {
                setConfirm(false);
                setUser({
                  ...user,
                  integral: user.integral - data.goods_price,
                });
                Dialog.success("兑换成功");
              }
            })
            .catch((err) => {
              Dialog.error("exchangeFlb " + err.message);
            });
        },
      });
    }
  });

  const notHeadRef = useRef(null);
  const sizeNotBody = useSize(notHeadRef);
  const [jurisdictionShow, setJurisdictionShow] = useState(false);
  let [keyword, setKeyword] = useState("");

  const [notGameList, setNotGameList] = useState([]);
  // 获取福利币不可用游戏列表
  // 搜索框change事件 开始搜索
  let timer = null; //计时器
  const getGameList = useLockFn(async (parms = {}) => {
    // clearTimeout(timer);
    // timer = setTimeout(async () => {
    // 在这里执行输入事件的处理逻辑
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      page,
      pageSize: 40,
      game_name: keyword,
    };
    if (parms.page) {
      tmp.page = parms.page;
    }

    console.log("keyword---------", keyword);

    await general.axios
      .get("/api/game/unusableFlb", {
        params: tmp,
      })
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        console.log("福利币不可使用游戏", data);
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        setInit(true);

        scrollDir = scroll?.top || 0;
        setScrollDir(scroll?.top || 0);

        if (parms.page) {
          setPage(parms.page);
        }
        let { data: list } = data;
        setNotGameList((datas) =>
          parms.page === 1 || !parms.page ? list : [...datas, ...list]
        );

        setMore(list.length > 0);
      })
      .catch((err) => {
        Dialog.error("unusableFlb " + err.message);
      });
    // }, 500);
  });

  const checkData = (num) => {
    noGameRef.current.scrollTop = 0;
    scroll.top = 0;
    setMore(true);
    scrollDir = 0;
    setScrollDir(0);
    setNotGameList([]);

    if (num) {
      getGameList({ page: 1 });
    }
  };

  const [isEmpty, setIsEmpty] = useState(true);

  return (
    <>
      <div className="credit-ptb">
        {datas.map((data) => (
          <div key={data.g_id} className="item">
            <div className="icon">
              <img src="/resources/credit/flb.png" />
            </div>
            <div className="info">
              <div className="ptb">{data.flb_num}福利币</div>
              <div className="credit">
                <span>{data.goods_price}</span>积分
              </div>
            </div>
            <div className="btn" onClick={() => exchange(data)}>
              兑换
            </div>
            {confirm ? (
              <div className="rebate-confirm">
                <div className="container">
                  <div className="body">
                    <div className="title">兑换提醒</div>
                    <div className="info">
                      <p>
                        1.返利以单日累计充值总和计算，如您今日还
                        需要充值强烈建议您完成后续充值后再提
                        交，可以拿到更高比例的返利哦！
                      </p>
                      <p>
                        2.部分游戏活动期间返利为活动时间内累计充
                        值总和，请您在活动结束后统一提交，活动
                        详情请查看游戏活动或咨询客服；
                      </p>
                    </div>
                  </div>
                  <div className="btns">
                    <div className="btn" onClick={() => setConfirm(false)}>
                      我再想想
                    </div>
                    <div className="btn" onClick={exchange}>
                      继续提交
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>

      <div
        className="flb-not-game"
        onClick={() => {
          setMore(true);
          keyword = "";
          setKeyword("");
          scrollDir = 0;
          setScrollDir(0);

          setIsEmpty(true);
          setNotGameList([]);
          getGameList({ page: 1 });
          setJurisdictionShow(true);
        }}
      >
        福利币不可用游戏&gt;
      </div>

      <CSSTransition in={jurisdictionShow} timeout={250} unmountOnExit={true}>
        <div className="flb-not-content">
          <div
            className="server-mask"
            onClick={() => {
              setJurisdictionShow(false);
            }}
          />
          <div className="server-main" ref={noGameRef}>
            <div
              style={{
                position: "relative",
                paddingBottom: "16px",
                overflow: "auto",
              }}
              ref={scrollRef}
            >
              <div className="gift-top" ref={notHeadRef}>
                <div className="down-title">
                  <span className="title">不可用游戏</span>
                  <img
                    className="close"
                    src="/resources/game/close.png"
                    alt=""
                    onClick={() => {
                      setJurisdictionShow(false);
                    }}
                  />
                </div>
                <div className="not-game-input">
                  <div className="flb-search-input">
                    <img
                      src="/resources/comment/search.png"
                      alt=""
                      className="icon"
                    />
                    <input
                      type="text"
                      value={keyword}
                      placeholder={"请输入游戏名"}
                      onChange={(e) => {
                        keyword = e.target.value;
                        setKeyword(e.target.value);
                      }}
                      onInput={(e) => {
                        if (e.target.value == "" && !isEmpty) {
                          setIsEmpty(true);
                          keyword = "";
                          setKeyword("");
                          console.log("nonennnnnnnnnnnnnnnn");
                          checkData(1);
                        }
                      }}
                      onCompositionEnd={(e) => {
                        setIsEmpty(false);
                        console.log("end----dddddddddd-----", e.target.value);
                        keyword = e.target.value;
                        setKeyword(e.target.value);
                        console.log("kkkkkkkkkkkkkkkk----", keyword);
                        checkData(1);
                      }}
                    />
                    {keyword ? (
                      <CloseOutlined
                        className="clear"
                        onClick={() => {
                          keyword = "";
                          setKeyword("");
                          setIsEmpty(true);
                          checkData(1);
                        }}
                      />
                    ) : null}
                  </div>
                  <div
                    className="btn"
                    onClick={() => {
                      setIsEmpty(true);
                      checkData(1);
                    }}
                  >
                    搜索
                  </div>
                </div>
              </div>

              {notGameList && notGameList.length > 0 ? (
                <div
                  className="not-game-main"
                  style={{ paddingTop: sizeNotBody?.height || "111px" }}
                >
                  {notGameList.map((game) => (
                    <div className="item" key={game.id}>
                      <div className="icon">
                        <Image src={game?.icon_url} alt="" />
                        {game.discount && game.discount < 1 && (
                          <div className="discount">
                            <span
                              style={{
                                fontSize: "0.24rem",
                                fontWeight: "bold",
                              }}
                            >
                              {parseFloat((game.discount * 10).toFixed(2))}
                            </span>
                            <span style={{ fontSize: "0.2rem" }}>折</span>
                          </div>
                        )}
                      </div>
                      <div className="name">
                        {game.game_name_main || game.game_name}
                      </div>
                      {game.game_name_branch && (
                        <div className="branch">
                          <p>{game.game_name_branch}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  style={{ paddingTop: sizeNotBody?.height + 99 || "211px" }}
                  className="no-data"
                >
                  未搜索到该游戏~
                </div>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
