import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import general from "../../general";
import { AlipayCircleOutlined, WechatOutlined } from "@ant-design/icons";
import { useLockFn } from "ahooks";
import Dialog from "../../lib/Dialog";

export default (props) => {
  useEffect(() => {
    props.setOperate(
      <Link to="/ptb/record" className="trade-index-notice">
        充值记录
      </Link>
    );
  }, []);
  const [user] = useActiveState("user");
  const [amount, setAmount] = useState(100);
  const [customizeAmount, setCustomizeAmount] = useState();
  const [pay_type, setPay_type] = useState("wxpay");
  const submit = useLockFn(async () => {
    Dialog.loading();
    await general.axios
      .post("/user/pay/order", {
        amount,
        pay_type,
        recharge_type: "ptb",
        pay_source_type: 2,
      })
      .finally(() => Dialog.close())
      .then(({ data: resData }) => {
        let { status, data } = resData;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        if (data.payData.url) {
          window.location.href = data.payData.url;
          return;
        }
        if (data.payData.wxPayUrl) {
          window.location.href = data.payData.wxPayUrl;
        }
        if (data.payData.zfbPayHtml) {
          window.location.href = data.payData.zfbPayHtml;
        }
      })
      .catch((error) => Dialog.error("pay order " + error.message));
  });
  return (
    <div className="ptb-index">
      <div className="ptb-amount">
        <div className="name">平台币余额</div>
        <div className="value">{user.platform_coins || "?"}</div>
      </div>
      <div className="amount-title">
        请选择充值金额<span>（1平台币=0.1元人民币）</span>
      </div>
      <div className="amount-item">
        {[50, 100, 500, 1000, 2000, 5000].map((item, index) => (
          <div
            key={index}
            className={"item" + (amount === item ? " active" : "")}
            onClick={() => setAmount(item)}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="amount-input">
        自定义金额：
        <input
          type="tel"
          inputMode="numeric"
          value={customizeAmount}
          placeholder="1-99999"
          onInput={(event) => {
            let value = event.target.value;

            if (value.length > 0 && value[0] === "0") {
              event.target.value = value.slice(1); // 删除开头的 0
            }

            // 如果用户输入了非数字字符（包括符号），则移除
            event.target.value = event.target.value.replace(/[^0-9]/g, "");

            // 如果输入的长度大于5，去掉最后一位
            if (event.target.value.length > 5) {
              event.target.value = event.target.value.slice(0, -1); // 移除最后一位
            }
          }}
          onChange={(event) => {
            setCustomizeAmount(event.target.value);
            setAmount(event.target.value);
          }}
        />
      </div>
      <div className="amount-result">
        可获得<span>{amount ? amount * 10 : 0}</span>平台币
      </div>
      <div className="pay-title">请选择支付方式</div>
      <div className="pay-type">
        <div
          className={"item" + ("alipay" === pay_type ? " active" : "")}
          onClick={() => setPay_type("alipay")}
        >
          <AlipayCircleOutlined className="alipay" />
          支付宝
        </div>
        <div
          className={"item" + ("wxpay" === pay_type ? " active" : "")}
          onClick={() => setPay_type("wxpay")}
        >
          <WechatOutlined className="wxpay" />
          微信
        </div>
      </div>

      <div className="pay-bottom">
        <div className="pay-btn" onClick={submit}>
          立即充值
        </div>

        <div className="intro">
          平台币说明：不会过期，不支持退款，请确定后充值！
        </div>
      </div>
    </div>
  );
};
