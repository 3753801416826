import ReactDOM from "react-dom";
import $ from "jquery";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { v4 as uuidv4 } from "uuid";

let dialogs, setDialogs;

const DialogItem = ({ dialog, index, dialogs }) => {
  // console.log("弹窗", dialog);
  useEffect(() => {
    if (dialog.duration) {
      setTimeout(() => {
        Dialog.close(index);
      }, dialog.duration * 1000);
    }
    if (dialog.onClose) {
      return () => {
        dialog.onClose();
      };
    }
  }, []);

  return (
    <div
      className={
        "dialog" +
        " dialog-" +
        dialog.className +
        (index === (dialogs.length - 1 && dialog.type === "toast") ||
        dialog.type !== "toast"
          ? " active"
          : "")
      }
    >
      {dialog.mask ? (
        <div
          className="dialog-mask"
          style={dialog.maskOpacity ? { opacity: dialog.maskOpacity } : {}}
          onClick={() => {
            if (dialog.type === "apply") {
              Dialog.close(index);
            }
          }}
        />
      ) : null}
      <div className="dialog-content">
        {dialog.type === "toast" ? (
          <div
            className="dialog-toast"
            style={{
              maxWidth:
                dialog.content &&
                dialog.content.indexOf("若遇到iOS系统键盘问题") !== -1
                  ? "80%"
                  : "",
              margin: "0 auto",
              textAlign: "center",
            }}
          >
            {dialog.icon ? (
              <div className="toast-icon">{dialog.icon}</div>
            ) : null}
            {dialog.content ? (
              <div className="toast-text">{dialog.content}</div>
            ) : null}
          </div>
        ) : dialog.type === "apply" ? (
          <div className="dialog-modal" style={{ minWidth: "90vw" }}>
            <div className="modal-body" style={{ padding: "20px 30px" }}>
              {dialog.title ? (
                <div className="apply-title">{dialog.title}</div>
              ) : null}
              {dialog.content ? (
                <div className="modal-content">{dialog.content}</div>
              ) : null}
            </div>
            {dialog.btns.length > 0 ? (
              <div className="modal-wel-btns">
                {dialog.btns.map((btn, index) =>
                  btn.text ? (
                    <div
                      className="applyGame"
                      onClick={() => {
                        btn.onPress && btn.onPress(index);
                      }}
                      key={index}
                    >
                      {btn.text}
                    </div>
                  ) : null
                )}
              </div>
            ) : null}
          </div>
        ) : dialog.type === "welfare" ? (
          <div className="dialog-modal" style={{ minWidth: "90vw" }}>
            <div className="modal-body">
              {dialog.title ? (
                <div className="wel-title">
                  <div
                    style={{ flex: 1, textAlign: "center", paddingTop: "10px" }}
                  >
                    {dialog.title}
                  </div>
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src="/resources/game/close.png"
                    alt=""
                    onClick={() => {
                      Dialog.close(index);
                    }}
                  />
                </div>
              ) : null}
              {dialog.content ? (
                <div
                  className="modal-content"
                  style={{
                    margin: "25px -4px 0",
                  }}
                >
                  {dialog.content}
                </div>
              ) : null}
            </div>
            {dialog.btns.length > 0 ? (
              <div className="modal-wel-btns">
                {dialog.btns.map((btn, index) =>
                  btn.text ? (
                    <div
                      className={
                        btn.text === "前往领取" ? "goToCollect" : "download"
                      }
                      onClick={() => {
                        Dialog.close(index);
                        btn.onPress && btn.onPress();
                      }}
                      key={index}
                    >
                      {btn.text}
                    </div>
                  ) : null
                )}
              </div>
            ) : null}
          </div>
        ) : dialog.type === "download" ? (
          <div className="dialog-modal" style={{ minWidth: "90vw" }}>
            <div className="modal-body" style={{ paddingBottom: "43px" }}>
              {dialog.title ? (
                <div className="wel-title">
                  <div
                    style={{ flex: 1, textAlign: "center", paddingTop: "10px" }}
                  >
                    {dialog.title}
                  </div>
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src="/resources/game/close.png"
                    alt=""
                    onClick={() => {
                      Dialog.close(index);
                    }}
                  />
                </div>
              ) : null}
              {dialog.content ? (
                <div
                  className="modal-content"
                  style={{
                    marginTop: "25px",
                  }}
                >
                  {dialog.content}
                  {dialog.onFun()}
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="dialog-modal">
            <div className="modal-body">
              {dialog.title ? (
                <div className="modal-title">{dialog.title}</div>
              ) : null}
              {dialog.content ? (
                <div className="modal-content">{dialog.content}</div>
              ) : null}
            </div>
            {dialog.btns.length > 0 ? (
              <div
                className={
                  "modal-btns" + (dialog.btns.length > 2 ? " column" : "")
                }
              >
                {dialog.btns.map((btn, index) => (
                  <div
                    className={"modal-btn " + btn.style}
                    onClick={() => {
                      btn.onPress && btn.onPress();
                      Dialog.close(index);
                    }}
                    key={index}
                  >
                    {btn.text}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

const DialogRender = () => {
  [dialogs, setDialogs] = useState([]);
  return (
    <TransitionGroup>
      {dialogs.map((dialog, index, dialogs) => (
        <CSSTransition key={dialog.id} timeout={250}>
          <DialogItem {...{ dialog, index, dialogs }} />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

(() => {
  if ($(".dialogs").length === 0) {
    $("body").append('<div class="dialogs"></div>');
  }

  if ($(".toast-mask").length === 0) {
    ReactDOM.render(<DialogRender />, $(".dialogs")[0]);
  }
})();

const Dialog = {};

Dialog.dialog = (dialog = { btns: [] }) => {
  setDialogs((dialogs) => [...dialogs, { ...dialog, id: uuidv4() }]);
};

Dialog.toast = ({ className, icon, content, duration, onClose, mask }) => {
  Dialog.dialog({
    type: "toast",
    duration,
    className,
    icon,
    content,
    onClose,
    mask,
    maskOpacity: 0.05,
  });
};

Dialog.apply = (title, content, onClose, param = {}) => {
  console.log(param);
  Dialog.dialog({
    type: "apply",
    mask: true,
    title: title,
    content,
    btns: [
      {
        text: param.btn,
        onPress: onClose,
      },
    ],
  });
};

Dialog.success = (content, onClose) => {
  Dialog.toast({
    icon: <CheckCircleOutlined />,
    content: content,
    duration: 1,
    mask: false,
    onClose,
  });
};

Dialog.error = (content, onClose) => {
  Dialog.toast({
    icon: <CloseCircleOutlined />,
    content: content,
    duration: 2,
    mask: false,
    onClose,
  });
};

Dialog.info = (content, onClose) => {
  Dialog.toast({
    content: content,
    duration: 3,
    mask: false,
    onClose,
  });
};

Dialog.loading = (content) => {
  Dialog.toast({
    className: "loading",
    icon: (
      <img
        src="/resources/loading.gif"
        style={{ width: "75px", maxWidth: "75px" }}
      />
    ),
    content: content,
    duration: 0,
    mask: true,
  });
};

Dialog.offline = (content) => {
  Dialog.toast({
    icon: <CloseCircleOutlined />,
    content: content,
    duration: 1,
    mask: false,
  });
};

Dialog.alert = (title, content, onClose, param = {}) => {
  Dialog.dialog({
    type: "modal",
    mask: true,
    title: title,
    content,
    btns: [
      {
        text: param.btn || "确定",
        onPress: onClose,
        style: "success",
      },
    ],
  });
};

Dialog.affirm = (title, content, onClose, onAffirm, param = {}) => {
  Dialog.dialog({
    type: "modal",
    mask: true,
    title: title,
    content,
    btns: [
      {
        text: param.close,
        onPress: onClose,
      },
      {
        text: param.affirm,
        onPress: onAffirm,
        style: "success",
      },
    ],
  });
};

Dialog.welfare = (title, content, onClose, onDown, param = {}) => {
  Dialog.dialog({
    type: "welfare",
    mask: true,
    title: title,
    content,
    btns: [
      {
        text: param.btn,
        onPress: onClose,
      },
      {
        text: param.btnDown,
        onPress: onDown,
      },
    ],
  });
};

Dialog.download = (title, content, onClose) => {
  Dialog.dialog({
    type: "download",
    mask: true,
    title: title,
    content,
    onFun: onClose,
  });
};

Dialog.confirm = ({ title, yes, no }) => {
  Dialog.dialog({
    type: "modal",
    mask: true,
    title,
    btns: [
      {
        text: "取消",
        onPress: no,
      },
      {
        text: "确定",
        onPress: yes,
      },
    ],
  });
};

Dialog.close = (param) => {
  setDialogs((dialogs) => {
    if (param === true) {
      return [];
    } else if (dialogs[param]) {
      dialogs.splice(param, 1);
    } else {
      dialogs.pop();
    }
    return [...dialogs];
  });
};

export default Dialog;
