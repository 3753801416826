import { MessageOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useInterval, useLockFn } from "ahooks";
import general from "../../general";
import useActiveState from "../../lib/useActiveState";
import { prod } from "../../config";
import Dialog from "../../lib/Dialog";
import { CSSTransition } from "react-transition-group";

// 交易、回收小号时  输入手机号
export default (props) => {
  const [user] = useActiveState("user");
  const [code, setCode] = useState();
  const [ing, setIng] = useState();
  const [time, setTime] = useState(0);
  const [mobile, setMobile] = useState(!prod ? "13227737527" : "");
  useInterval(() => {
    if (time) {
      setTime(time - 1);
    }
  }, 1000);
  const get = useLockFn(async () => {
    if (ing || time) {
      return;
    }
    setIng(true);
    Dialog.loading();
    await general.axios
      .post("/base/tool/getVerifyCode", {
        code: "code",
        type: props.type,
        mobile: "1",
      })
      .finally(() => {
        setIng(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setTime(60);
          setMobile(data.mobile);
          if (!prod) {
            setCode(data.verify_code);
          }
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  });
  const verify = useLockFn(async () => {
    if (!code) {
      Dialog.error("请输入验证码");
      return;
    }
    Dialog.loading();
    await general.axios
      .post("/base/tool/checkPhoneCode", { code, mobile })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let { status } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        props.close && props.close();
        props.onSuccess && props.onSuccess();
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  });

  return (
    <CSSTransition
      in={props.show}
      timeout={250}
      appear={true}
      unmountOnExit={true}
    >
      <div className="user-phone-verify">
        <div className="entity">
          <div className="container">
            <div className="body">
              <MessageOutlined className="icon" />
              <div className="title">请输入验证码</div>
              <div className="desc">向您的手机号码{user.mobile}发送验证码</div>
              <div className="input">
                <input
                  type="text"
                  placeholder="请输入验证码"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <div
                  className={"btn" + (ing || time ? " disable" : "")}
                  onClick={get}
                >
                  {ing ? "发送中" : time ? time + "秒后重发" : "发送验证码"}
                </div>
              </div>
            </div>
            <div className="submit" onClick={verify}>
              确定
            </div>
          </div>
          <div className="close" onClick={() => props.close && props.close()}>
            X
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
