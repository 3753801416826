import React, { useEffect, useState } from "react";
import general, { imgPlaceholder } from "../../general";
import { Link, useHistory } from "react-router-dom";
import Image from "../../lib/Image";
import Dialog from "../../lib/Dialog";

// 专题信息展示
export default (props) => {
  const history = useHistory();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const getProjectDetail = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let data = {};
    data.type = props.query.type;
    if (props.query.id) {
      data.id = props.query.id;
    } else {
      Dialog.error("缺少参数", 1, () => history.goBack);
      return;
    }
    general.axios
      .post("/base/appspecial/getSpecialList", data)
      .finally(() => {
        setLoading(false);
      })
      .then((res) => {
        let { status, data } = res.data;
        console.log("专题详情", res);
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        if (!data.list.url_img) {
          Dialog.error("该活动已过期...");
          setTimeout(() => {
            general.history.push("/");
          }, 1000);
        } else {
          setData(data.list);
          console.log("专题详情", data);
        }
      })
      .catch((err) => {
        Dialog.error("getProjectDetail " + err.message);
      });
  };

  useEffect(() => {
    getProjectDetail();
  }, []);

  useEffect(() => {
    props.setTitle(data?.title);
  }, [data]);

  return (
    <>
      {data && !loading ? (
        <>
          <div className="game-project">
            <div className="project-img">
              <Image src={data.url_img} alt="" />
            </div>
            <div className="project-info">{data.describe}</div>
            <div className="games">
              {data.details?.map((game) => (
                <Link
                  key={game.game_id}
                  to={"/game?id=" + game.game_id}
                  className="item"
                >
                  <div className="icon">
                    <Image src={game.icon} />
                    {game.game_type == 2 && game.discount < 1 ? (
                      <div className="index-all-discount">
                        <span
                          style={{ fontSize: "0.24rem", fontWeight: "bold" }}
                        >
                          {parseFloat((game.discount * 10).toFixed(2))}
                        </span>
                        <span style={{ fontSize: "0.2rem" }}>折</span>
                      </div>
                    ) : null}
                  </div>
                  <div className="data">
                    <div className="name">{game.game_name_main}</div>
                    <div className="desc">
                      {game.game_name_branch ? (
                        <div className="branch">{game.game_name_branch}</div>
                      ) : null}
                      {game.version}
                      &nbsp;·&nbsp;
                      {game.size}
                    </div>
                    <div className="info">
                      {game.benefits?.split("+").map((item, index) => (
                        <div key={index} className="tag">
                          {item}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="btn">下载</div>
                </Link>
              ))}
            </div>
            <div className="view-end">别撩啦！已经到底啦~</div>
          </div>
        </>
      ) : (
        <div className="game-detail">
          <div className="game-skeleton">
            <div className="a" />
            <div className="b">
              <img src={imgPlaceholder.default} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
